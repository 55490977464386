import { FormikErrors, FormikHandlers, FormikProps, FormikTouched } from "formik";
import { ApprovalTypes, DocumentType, PageEnum, daysOfWeeks, documentStatus, repeatValue } from "./constant";

export interface ResponseInterface<DataInterface> {
    status: number;
    message: string;
    data?: DataInterface;
    metaData?: MetaDataInterface;
    success: boolean;
}

export interface MetaDataInterface {
    total: number;
    page: number;
}

export interface LoginInterface {
    email: string;
    password: string;
}

export interface MetaFilterInterface {
    fieldName?: JobFilterFields;
    startDate?: string | null;
    endDate?: string | null;
    schedulerId?: SchedulerForSelect[] | null;
    centreId?: CentreForSelect[] | null;
    positionId?: PositionForSelect[] | null;
    relieverId?: RelieverForSelect[] | null;
    value?: null | string;
}

export interface ReportFormInterface {
    fieldName?: ReportFormFields;
    from: string;
    to: string;
    scheduler?: SchedulerForSelect | null;
    schedulerId?: string | null;
    reliever?: RelieverForSelect | null;
    relieverId?: string | null;
}

export interface ReportResponseInterface {
    scheduler?: SchedulerInterface;
    reliever?: RelieverInterface;
    totalAmountToBill?: number;
    totalAmountToPay?: number;
    totalHours: number;
    totalNoOfCompletedJobs: number;
}

export interface CentreForSelect {
    centreId: string;
    centreName: string;
}

export interface RelieverForSelect {
    relieverId: string;
    name: string;
}

export interface PositionInterface {
    name: string;
    positionId?: number;
}

export interface PositionForSelect {
    name: string;
    positionId: string;
}

export interface AgeGroupInterface {
    name: string;
    ageGroupId?: number;
    isActive?: boolean;
}

export interface AgeGroupTableInterface {
    name: string;
    ageGroupId: number;
    isActive: boolean;
}

export interface JobHoursInterface {
    id: number;
    jobId: number;
    relieverId: number;
    clockIn: string;
    clockOut: string;
    isApproved: boolean;
    breakTime: string;
    date?: Date | string;
    createdAt?: Date | string;
    job: JobInterface;
}

export interface JobHoursTableInterface {
    id: number;
    jobId: number;
    relieverId: number;
    clockIn: Date;
    clockOut: Date;
    isApproved: boolean;
    breakTime: string;
    reliever: RelieverInterface;
    job: { jobId: number; scheduler: SchedulerInterface; centreForJob: CentreInterface; startDate: Date | string; endDate: Date | string };
    createdAt: Date;
    deletedAt: Date;
}

export interface InvoiceTableInterface {
    invoiceId: number;
    schedulerId: number;
    invoiceFile: string;
    description: string;
    fromDate: Date;
    toDate: Date;
    dueDate: Date;
    amount: number;
    scheduler: SchedulerInterface;
    deletedAt?: Date | null;
}

export interface InvoiceInterface {
    invoiceId?: number;
    schedulerId: string;
    invoiceFile: string;
    description: string;
    fromDate: string;
    toDate: string;
    amount: string;
    scheduler?: SchedulerForSelect | null;
    dueDate?: Date | string;
}

export interface ActivateDeactivatePositionInterface {
    positionId: number;
    isActive: boolean;
}

export interface ActivateDeactivateSchedulerInterface {
    schedulerId: number;
    isActive: boolean;
}

export interface ActivateDeactivateRelieverInterface {
    relieverId: number;
    isActive: boolean;
}

export interface ActivateDeactivateCentreInterface {
    centreId: number;
    isActive: boolean;
}

export interface ActivateDeactivateBreakInterface {
    breakId: number;
    isActive: boolean;
}

export interface ActivateDeactivateAgeGroup {
    ageGroupId: number;
    isActive: boolean;
}

export interface BreakInterface {
    breakId?: number;
    breakTime: string;
    time: number;
    isActive?: boolean;
}

export interface BreakTableInterface {
    breakId: number;
    breakTime: string;
    time: number;
    isActive: boolean;
}

export interface RegionInterface {
    regionId: number;
    regionName: string;
}

export interface DistrictInterface {
    districtId: number;
    regionId: number;
    districtName: string;
    region?: RegionInterface;
}

export interface SuburbInterface {
    districtId: number;
    suburbId: number;
    suburbName: string;
    district?: DistrictInterface;
}

export interface DeleteModalInterface {
    isOpen: boolean;
    onDelete: () => void;
    handleCancel: () => void;
    subTitle: string;
}

export interface NotificationModalInterface {
    isOpen: boolean;
    handleCancel: () => void;
    relieverId?: number;
    schedulerId?: number;
}

export interface RelieverModalPayload {
    isOpen: boolean;
    handleCancel: () => void;
    relieverList: RelieverInterface[] | null;
}

export interface RejectModalInterface {
    isOpen: boolean;
    onReject: (status: DocumentStatus) => void;
    handleCancel: () => void;
    subTitle: string;
}

export interface ApprovalUserModalInterface {
    isOpen: boolean;
    onOk: () => void;
    handleCancel: () => void;
}

export interface ZIndexMaintainer {
    [key: string]: string | undefined;
}

export interface UploadModalInterface {
    isOpen: boolean;
    handleCancel: () => void;
    subTitle: string;
    files: PreviewFilesInterface[];
    setFiles: React.Dispatch<React.SetStateAction<PreviewFilesInterface[]>>;
    onSubmit: () => void;
    onReset: () => void;
}

export interface LoadingInterface {
    forTable?: boolean;
}

export interface StateInterface {
    base: BaseSliceInterface;
}

export interface BaseSliceInterface {
    page: (typeof PageEnum)[number];
    pagePath: PathInterface[];
    loading: boolean;
    tableData: TableDataInterface;
}

export interface TableDataInterface {
    page: number;
    limit: number;
    maxPage: number;
    search: string | null;
    orderBy: OrderInterface | null;
    tableFor: string | null;
    tabFor: string;
    metaFilter?: MetaFilterValues | AvailabilityFilterInterface | null;
    metaFilterData: MetaFilterInterface;
}

export interface TableDataPayloadInterface {
    payload: TableDataInterface;
    type: string;
}

export interface PathInterface {
    pathName: string;
    href: string;
}

export interface SetPagePathStateInterface {
    payload: PathInterface[];
    type: string;
}

export interface SetBooleanInterface {
    payload: boolean;
    type: string;
}

export interface SetPageStateInterface {
    payload: (typeof PageEnum)[number];
    type: string;
}

export interface DashboardCardInterface {
    title: string;
    value: number;
    percentage: string;
    description: string;
}

export enum OrderEnum {
    ASC = "ASC",
    DESC = "DESC",
}

export interface TableInterface<T> {
    cols: ColInterface<T>[];
    data: T[];
    tableKey: keyof T;
    reload?: () => void;
    component?: JSX.Element;
    currentOrder?: OrderInterface | null;
    tableOffset: TableOffset;
    error?: string | null;
    loading: boolean;
    onSetOrderBy: (fieldName: string, order: OrderEnum) => void;
}

export interface QueryFunctionInterface {
    page: number;
    limit: number;
    search?: string | null;
    fieldName?: string;
    order?: string;
    value?: string | null;
}

export interface FilterForJobTable extends FormInterface<MetaFilterInterface> {
    reload: (url?: string) => void;
}

export interface CustomDatePickerProps {
    name: string;
    id: string;
    selected?: Date | null;
    minDate?: Date | null;
    hasError?: boolean;
    maxDate?: Date | null;
    setFieldValue?: (field: string, value: Date | null | string, shouldValidate?: boolean) => void;
    handleBlur?: FormikHandlers["handleBlur"];
    disabled?: boolean;
    customChangeFunction?: (index: number, expiryDate: Date | null) => void;
    metaData?: number;
    onChangeEffect?: () => void;
    onCalendarOpen?: () => void;
    onCalendarClose?: () => void;
}

export interface ReportProps extends FormInterface<ReportFormInterface> {
    setReportDetails: React.Dispatch<React.SetStateAction<ReportResponseInterface | null>>;
}

export interface TableOffset {
    onNext: () => void;
    onPrevious: () => void;
    onSetPage: (pageNum: number) => void;
    onSetLimit: (limitNum: number) => void;
    total: number;
    page: number;
    limit: number;
    maxPage: number;
}

export interface MetaFilterValues {
    fieldName?: JobFilterFields;
    value?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    schedulerId?: SchedulerForSelect[] | null;
    showApproved?: boolean;
}

export interface ModalInterface {
    isOpen: boolean;
    children: React.ReactNode;
}

export enum Alignment {
    RIGHT = "right",
    LEFT = "left",
    CENTER = "center",
}

export interface ColInterface<T> {
    key: string;
    sortable?: boolean;
    title: string;
    render: (record: T, index: number) => JSX.Element;
    align?: Alignment;
}

export interface FormInterface<T> extends FormikProps<T> {
    errors: FormikErrors<T>;
    touched: FormikTouched<T>;
    handleChange: FormikHandlers["handleChange"];
    values: T;
    handleBlur: FormikHandlers["handleBlur"];
}

export interface CentreTableInterface {
    centreId: number;
    centreName: string;
    schedulerId: number[];
    streetAddress?: string;
    suburb: SuburbInterface;
    suburbId: number;
    postalCode: number;
    isActive: boolean;
}

export interface CentreInterface {
    centreId?: number;
    centreName: string;
    schedulerId?: SchedulerForSelect[] | null;
    streetAddress: string;
    addressLine: string;
    schedulerList?: SchedulerRelation[];
    suburb?: SuburbInterface | null;
    region?: RegionInterface | null;
    district?: DistrictInterface | null;
    suburbId?: number | null;
    showSelectReliever: boolean;
    postalCode?: string | null;
}

export interface SchedulerRelation {
    centreId: number;
    schedulerId: number;
    scheduler: SchedulerForSelect;
}

export interface PositionTableInterface {
    positionId: number;
    name: string;
    isActive: boolean;
}

export interface OrderInterface {
    fieldName: string;
    order: OrderEnum;
}

export interface CentreAndSchedulerId {
    centreId: number;
    schedulerId: number;
    centreDetails: CentreForSelect;
}

export interface SchedulerInterface {
    schedulerId?: number;
    name: string;
    email: string;
    mobileNumber: string;
    centre?: CentreForSelect[] | null;
    profileImg?: File;
    isActive?: boolean;
    centreList?: CentreAndSchedulerId[];
    dob?: Date | null;
    deletedAt?: Date | null;
}

export enum SignUpPlatform {
    ADMIN = "Admin",
    APP = "App",
    WEB = "Web",
}

export interface SchedulerTableInterface {
    schedulerId: number;
    name: string;
    email: string;
    mobileNumber: string;
    signUpPlatform: SignUpPlatform;
    isActive: boolean;
    approval: ApprovalTypes;
    dob?: Date;
    deletedAt?: Date;
}

export interface SchedulerForSelect {
    schedulerId: string;
    name: string;
}
export interface SchedulerForSelectFilter {
    schedulerId: number;
    name: string;
}
export interface RelieverForSelect {
    relieverId: string;
    name: string;
}

export interface SelectInterface {
    value: string;
    label: string;
}

export interface repeatStartDate {
    [0]: SelectInterface;
    [1]: Date;
}

export interface DocumentsInterface {
    documentId: number;
    name: string;
    documentType: DocumentType;
    url: string;
    relieverId: number;
    status: DocumentStatus;
    expire: Date | null;
    expiryDate: Date | null;
}

export interface RelieverInterface {
    relieverId: number;
    name: string;
    email: string;
    mobileNumber: string;
    isActive?: boolean;
    dob?: Date;
    registrationNo: number;
    region: RegionInterface;
    district: DistrictInterface;
    signUpPlatform: SignUpPlatform;
    approval: ApprovalTypes;
    positionId: number;
    expiryOfRegistration?: Date | null;
    suburb: SuburbInterface;
    position: PositionInterface;
    documents?: DocumentsInterface[];
    deletedAt?: Date | null;
    isAssigned: boolean;
}

export interface RelieverForSendSpecific extends RelieverInterface {
    jobs?: RelieverForSendSpecificJob[];
}

export interface RelieverForSendSpecificJob {
    jobId: number;
}

export interface BankDetails {
    relieverId?: number;
    IRDNumber?: number;
    IRDFile?: string;
    taxCode?: string;
    kiwiSaver?: string;
    bank?: string;
    branch?: string;
    account?: string;
    suffix?: string;
    bankAccountName?: string;
}

export interface PrimaryDocs {
    relieverId: number;
    academicTranscripts?: string | null;
    portrait?: string | null;
    cv?: string | null;
    workingEligibility?: string | null;
    primaryProofType?: PrimaryProofTypeEnum | null;
    primaryProofFile?: string;
    secondaryProofType?: SecondaryProofTypeEnum | null;
    secondaryProofFile?: string | null;
    workingHoursLetter?: string | null;
}

export interface AddEditRelieverInterface {
    relieverId?: number;
    name?: string;
    email?: string;
    mobileNumber?: string;
    wage?: number | null;
    isActive?: boolean;
    dob?: Date | null;
    registrationNo?: number | null;
    expiryOfRegistration?: Date | null;
    aidCertificate?: boolean;
    about?: string | null;
    expiryOfAidCertificate?: Date | null;
    region?: RegionInterface | null;
    profileImg?: File;
    streetAddress?: string | null;
    addressLine?: string | null;
    postalCode?: string | null;
    positionId?: number;
    district?: DistrictInterface | null;
    suburb?: SuburbInterface | null;
    position?: PositionInterface | null;
    grade?: GradeType | null;
    unavailability?: Date[] | null;
    deletedAt?: Date | null;
    academicTranscripts?: string | null;
    portrait?: string | null;
    cv?: string | null;
    workingEligibility?: string | null;
    primaryProofType?: PrimaryProofTypeEnum | null;
    primaryProofFile?: string | null;
    secondaryProofType?: SecondaryProofTypeEnum | null;
    secondaryProofFile?: string | null;
    workingHoursLetter?: string | null;
    IRDNumber?: number | null;
    bank?: string | null;
    branch?: string | null;
    account?: string | null;
    suffix?: string | null;
    IRDFile?: string;
    bankAccountName?: string | null;
    signUpPlatform?: string;
    bankDetails?: BankDetails;
    expiryOfPrimaryProofType?: Date | null;
    expiryOfSecondaryProofType?: Date | null;
    documents?: DocumentsInterface[];
}

export interface ChangePasswordInterface {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface ToastLinkInterface {
    primaryLinkTitle: string;
    primaryLink: string;
    secondaryLinkTitle?: string;
    secondaryLink?: string;
}

export interface SearchFormInterface {
    search: string;
}

export interface SearchFormComponentInterface {
    resetSearch: () => void;
    onSearch: (value: string) => void;
    searchPlaceHolder?: string;
}

export type RepeatInterface = (typeof repeatValue)[number];

export interface AddEditJobInterface {
    jobId?: number;
    scheduler: SchedulerForSelect | null;
    centreForJob: CentreInterface | null;
    note?: string;
    ageGroupForJob: AgeGroupTableInterface | null;
    positionForJob: Required<PositionInterface> | null;
    breakForJob: Required<BreakInterface> | null;
    startTime: string;
    endTime: string;
    startDate: Date | null | string;
    repeat: RepeatInterface | null;
    endDate: Date | null | string;
    noOfRelievers: number;
    daysOfWeek?: SelectInterface[] | null;
    relieverSelection?: boolean;
    notifyRelievers: boolean;
    reliever?: RelieverInterface[];
    inFavorite?: boolean;
    inDistrict?: boolean;
    sendSpecific?: boolean;
    status?: string;
}

export interface CombineDateAndTime {
    startDate: Date;
    endDate: Date;
}

export interface JobManagement {
    jobId: number;
    relieverId: number;
    reliever: RelieverInterface;
}

export interface JobInterface {
    jobId: number;
    scheduler: SchedulerForSelect;
    centreForJob: CentreInterface;
    note?: string;
    ageGroupForJob: AgeGroupTableInterface;
    positionForJob: Required<PositionInterface>;
    breakForJob: Required<BreakInterface>;
    startTime: string;
    endTime: string;
    startDate: Date;
    repeat: RepeatInterface;
    endDate: Date;
    noOfRelievers: number;
    daysOfWeek?: SelectInterface[] | null;
    relieverSelection?: boolean;
    reliever?: RelieverInterface[];
    jobManagement?: JobManagement[];
    inFavorite?: boolean;
    inDistrict?: boolean;
    sendSpecific?: boolean;
}

export type DaysOfWeekEnum = (typeof daysOfWeeks)[number];

export interface CentreForSelectInterface {
    centreId: number;
    centreDetails: CentreDetailsInterface;
}

export interface CentreDetailsInterface {
    centreName: string;
    showSelectReliever: boolean;
}

export interface CommonDataForJobInterface {
    break: Required<BreakInterface[]>;
    position: Required<PositionInterface[]>;
    ageGroup: AgeGroupTableInterface[];
}

export interface PreviewFilesInterface extends File {
    preview: string;
}

export interface GlobalSettingsInterface {
    cancelJob: number;
    updateJob: number;
    withdrawJob: number;
    createJob: number;
    OTPResend: number;
    OTPExpire: number;
    contactUsEmail: string;
    deactivationEmail: string;
    birthday: string | File;
    workAnniversary: string | File;
}

export interface RelieverSelectionPayload {
    startDate?: string | null;
    endDate?: string | null;
    positionId?: number;
    repeat?: string | null;
    daysOfWeek?: string | null | undefined;
}

export interface DocumentStateInterface {
    isPrivate: boolean;
    expiryDate: Date | null;
    documentType: DocumentType;
    document: PreviewFilesInterface;
}

export interface DocumentUploadInterface {
    isOpen: boolean;
    handleCancel: () => void;
    subTitle: string;
    files: DocumentStateInterface[];
    setFiles: React.Dispatch<React.SetStateAction<DocumentStateInterface[]>>;
    onSubmit: () => void;
    onReset: () => void;
}

export type DocumentStatus = (typeof documentStatus)[number];

export interface DocumentEditInterface {
    documentId: number;
    name: string;
    documentType: DocumentType;
    status: DocumentStatus;
    expiryDate: Date | null;
}

export interface AvailabilityInterface {
    date: string;
    position: Partial<PositionInterface>;
    status: status;
}

export interface AvailabilityFilterInterface {
    date: string;
    status: status;
    positionId: string | number;
}

export interface AvailableRelieverTableInterface {
    relieverId: number;
    name: string;
}

export interface FeedbackTableInterface {
    relieverId: number;
    rating: number;
    feedBack: string;
    reliever: RelieverInterface;
    scheduler: SchedulerInterface;
    centre: {
        centreId: number;
        centreName: string;
    };
}

export interface FeedbackExtraInterface {
    reliever?: RelieverInterface;
    scheduler?: SchedulerInterface;
}

export enum status {
    available = "Available",
    unavailable = "Unavailable",
    accepted = "Accepted",
}

export enum JobFilterFields {
    date = "Date",
    scheduler = "Scheduler",
    position = "Position",
    centre = "Centre",
    reliever = "Reliever",
}

export enum ReportFormFields {
    scheduler = "Scheduler",
    reliever = "Reliever",
}

export enum PrimaryProofTypeEnum {
    passport = "Passport",
    nzFirearmsLicense = "NZ Firearms License",
    nzFullBirthCertificate = "NZ Full Birth Certificate (issued on or after 1991)",
    nzCitizenshipCertificate = "NZ Citizenship Certificate",
    nzRefugeeTravelDocument = "NZ Refugee Travel Document",
    nzEmergencyTravelDocument = "NZ Emergency Travel Document",
    nzCertificateOfIdentity = "NZ Certificate of Identity",
}

export enum SecondaryProofTypeEnum {
    originalDriverLicense = "Original Driver’s License",
    card18 = "18+ card",
    nzFullBirthCertificate = "NZ Full Birth Certificate (issued before 1998)",
    communityServiceCard = "Community Services card",
    superGoldCard = "SuperGold Card",
    nzEmployeePhotoIdentificationCard = "NZ Employee Photo Identification Card",
    nzStudentPhotoIdentificationCard = "NZ Student Photo Identification Card",
    inlandRevenueNumber = "Inland Revenue number",
    nzissuedUtilityBill = "NZ issued utility bill (issued not more than six months earlier)",
    nzTeachersRegistrationCertificate = "NZ Teachers Registration certificate",
    nzElectoralRollRecord = "NZ Electoral Roll Record",
    internationalDrivingPermit = "International Driving Permit",
    stepstoFreedomForm = "Steps to Freedom Form",
}

export interface ImageComponetInterface {
    setFieldValue: (field: string, value: File | null | string, shouldValidate?: boolean) => void;
    fieldName: string;
    value: string | null | undefined;
    isEdit?: boolean;
}
export interface ApprovalState {
    id: number;
    status: ApprovalTypes;
}

export interface SpecificUserNotification {
    title: string;
    description: string;
}
export enum GradeType {
    TIER_1 = "Tier 1",
    TIER_2 = "Tier 2",
    TIER_3 = "Tier 3",
}

export interface dashboardInterface {
    totalCompletedJob: number;
    totalReliever: number;
    totalScheduler: number;
    totalUser: number;
    unApprovedReliever: number;
    unApprovedScheduler: number;
    totalUnApprovedUser: number;
    analytics: {
        profileView: number;
        relieverProfileView: number;
        newJob: number;
        pendingJob: number;
        activeJob: number;
        completedJob: number;
        cancelJob: number;
        editJob: number;
        acceptJob: number;
        duplicateJob: number;
        ignoreJob: number;
        withdrawJob: number;
        applyJob: number;
        "re-applyJob": number;
        favorites: number;
        feedback: number;
        downloadDocument: number;
        timeSheetList: number;
        approveTimeSheet: number;
        modifyTimeSheet: number;
        downloadInvoice: number;
        viewNotificationHistory: number;
        clearNotification: number;
        totalCompletedJob: number;
    };
}

export const filterType = <const>["Week", "Month", "Year", "Custom"];

export type FilterTypeEnum = (typeof filterType)[number];

export interface DashboardFilterInterface {
    userType?: string | null;
    relieverId?: RelieverForSelect | null;
    schedulerId?: SchedulerForSelect | null;
    filterType?: FilterTypeEnum | null;
    from?: Date | null;
    to?: Date | null;
}

export interface FilterForDashboard extends FormInterface<DashboardFilterInterface> {
    fetchData: (values: DashboardFilterInterface) => void;
}

export enum TimesheetFormFields {
    scheduler = "Scheduler",
    reliever = "Reliever",
    centre = "Centre Name",
}

export interface TimeSheetFilterValues {
    fieldName?: TimesheetFormFields;
    schedulerId?: string | null;
    relieverId?: string | null;
    centreId?: string | null;
    startDate?: string | null;
    endDate?: string | null;
}
