/* eslint-disable indent */
import { AlertTriangle, Edit, Trash2 } from "react-feather";
import { useNavigate } from "react-router-dom";
import { api } from "../../config/Api";
import {
    JobHoursTableInterface,
    MetaFilterInterface,
    StateInterface,
    TimeSheetFilterValues,
    TimesheetFormFields,
} from "../../helper/interface";
import usePathName from "../../hooks/usePathName";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import { Tooltip } from "react-tooltip";
import moment from "moment";
// import SearchForm from "../table/SearchForm";
import DeleteModal from "../modal/DeleteModal";
import { useCallback, useState } from "react";
import { onDeleteTimeSheet } from "../../service/timeSheet";
import { useDispatch, useSelector } from "react-redux";
import { isDateInCurrentWeek, timeSheetFilterValidation } from "../../helper/constant";
import { Formik } from "formik";
import TimeSheetForm from "./TimesheetForm";
import { setTableData } from "../../store/slice/BaseSlice";

const initialValue: TimeSheetFilterValues = {
    fieldName: TimesheetFormFields.scheduler,
    schedulerId: null,
    relieverId: null,
    centreId: null,
    startDate: null,
    endDate: null,
};

const RelieverJobHours = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [timeSheet, setTimeSheet] = useState<JobHoursTableInterface | null>(null);
    const dispatch = useDispatch();

    const tableData = useSelector((state: StateInterface) => state.base.tableData);

    const navigate = useNavigate();
    usePathName([{ pathName: "Timesheet", href: "/timesheet" }]);

    const jobTimeTable = useTable<JobHoursTableInterface>("relieverJobHours", api.admin.getRelieverJobHours);

    const onDeleteClick = useCallback((record: JobHoursTableInterface) => {
        setTimeSheet(record);
        setIsOpen(true);
    }, []);

    const onDelete = () => {
        if (timeSheet?.id) {
            onDeleteTimeSheet(timeSheet.id, dispatch, jobTimeTable.fetchApi);
            setIsOpen(false);
        }
    };

    const jobTimeCols = [
        {
            title: "#",
            key: "id",
            render: (record: JobHoursTableInterface, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "SCHEDULER NAME",
            key: "schedulername",
            sortable: false,
            render: (record: JobHoursTableInterface) => {
                return <span>{record?.job?.scheduler?.name}</span>;
            },
        },
        {
            title: "CENTRE NAME",
            key: "centrename",
            sortable: false,
            render: (record: JobHoursTableInterface) => {
                return <span>{record?.job?.centreForJob?.centreName}</span>;
            },
        },
        {
            title: "RELIEVER NAME",
            key: "relievername",
            sortable: false,
            render: (record: JobHoursTableInterface) => {
                return <span>{record?.reliever?.name}</span>;
            },
        },
        {
            title: "CLOCK IN",
            key: "clockin",
            sortable: true,
            render: (record: JobHoursTableInterface) => {
                const isDateInCurrentWeekOrNot = isDateInCurrentWeek(record?.createdAt);

                const date = moment.utc(record?.createdAt).format("YYYY-MM-DD");

                const time = moment.utc(record?.job?.startDate).format("HH:mm:ss");

                const combinedDateTime = moment.utc(date + " " + time, "YYYY-MM-DD HH:mm:ss");
                const localDateTime = combinedDateTime.local().format("DD/MM/YYYY HH:mm:ss");

                const CLOCK_IN = record?.clockIn
                    ? moment(record?.clockIn).format("DD/MM/YYYY HH:mm:ss")
                    : isDateInCurrentWeekOrNot
                    ? "--"
                    : localDateTime;

                return <span>{CLOCK_IN}</span>;
            },
        },
        {
            title: "CLOCK OUT",
            key: "clockOut",
            sortable: true,
            render: (record: JobHoursTableInterface) => {
                const isDateInCurrentWeekOrNot = isDateInCurrentWeek(record?.createdAt);

                const date = moment.utc(record?.createdAt).format("YYYY-MM-DD");

                const time = moment.utc(record?.job?.endDate).format("HH:mm:ss");

                const combinedDateTime = moment.utc(date + " " + time, "YYYY-MM-DD HH:mm:ss");
                const localDateTime = combinedDateTime.local().format("DD/MM/YYYY HH:mm:ss");

                const CLOCK_OUT = record?.clockOut
                    ? moment(record?.clockOut).format("DD/MM/YYYY HH:mm:ss")
                    : isDateInCurrentWeekOrNot
                    ? "--"
                    : localDateTime;

                return <span>{CLOCK_OUT}</span>;
            },
        },
        {
            title: "BREAK TIME",
            key: "breaktime",
            sortable: false,
            render: (record: JobHoursTableInterface) => {
                return <span>{record?.breakTime || "--"}</span>;
            },
        },
        {
            title: "TOTAL WORKING HOURS",
            key: "total working hours",
            sortable: false,
            render: (record: JobHoursTableInterface) => {
                let formattedHours = "00";
                let formattedMinutes = "00";
                if (record?.clockIn && record?.clockOut) {
                    const date1: Date = new Date(record.clockIn);
                    const date2: Date = new Date(record.clockOut);

                    const differenceMs: number = Math.abs(date2.getTime() - date1.getTime());

                    let totalMinutes: number = Math.ceil(differenceMs / (1000 * 60));

                    if (record?.breakTime) {
                        totalMinutes -= parseInt(record?.breakTime);
                    }

                    const adjustedHours: number = Math.floor(totalMinutes / 60);
                    const remainingMinutes: number = totalMinutes % 60;

                    formattedHours = adjustedHours.toString().padStart(2, "0");
                    formattedMinutes = remainingMinutes.toString().padStart(2, "0");
                }
                return <span>{formattedHours + ":" + formattedMinutes}</span>;
            },
        },
        {
            title: "MISSED PUNCH",
            key: "miss punch",
            render: (record: JobHoursTableInterface) => {
                const isDateInCurrentWeekOrNot = isDateInCurrentWeek(record?.createdAt);

                const MISS_PUNCH = !isDateInCurrentWeekOrNot && !record?.clockIn && !record?.clockOut ? true : false;

                return <div>{MISS_PUNCH ? <span style={{ color: "red", fontSize: "20px" }}>!</span> : "--"}</div>;
            },
        },
        {
            title: "ACTION",
            key: "action",
            render: (record: JobHoursTableInterface) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDeleteAgeGroup" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeleteAgeGroup"
                                data-tooltip-content={"Edit job hours"}
                                onClick={() => navigate(`/timesheet/edit/${record.id}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                Edit
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDeletePosition"
                                data-tooltip-content={"Delete position"}
                                onClick={() => onDeleteClick(record)}
                            >
                                {record?.deletedAt ? (
                                    <AlertTriangle className="w-4 h-4 mr-1" />
                                ) : (
                                    <>
                                        <Trash2 className="w-4 h-4 mr-1" />
                                        Delete
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const handleCancel = () => {
        setIsOpen(false);
    };

    const onTimesheetSubmit = (values: TimeSheetFilterValues) => {
        const metaFilters: TimeSheetFilterValues = {};

        if (values.schedulerId) metaFilters.schedulerId = values?.schedulerId;

        if (values.centreId) metaFilters.centreId = values?.centreId;

        if (values.relieverId) metaFilters.relieverId = values?.relieverId;

        if (values?.startDate) metaFilters.startDate = moment(values.startDate)?.startOf("day").toISOString();

        if (values?.endDate) metaFilters.endDate = moment(values.endDate)?.endOf("day").toISOString();

        dispatch(
            setTableData({ ...tableData, metaFilter: metaFilters as MetaFilterInterface, metaFilterData: values as MetaFilterInterface })
        );

        jobTimeTable.onSearch<MetaFilterInterface>("", metaFilters as MetaFilterInterface);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this position?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={jobTimeCols}
                    data={jobTimeTable.tableData ?? []}
                    tableKey="id"
                    reload={jobTimeTable.fetchApi}
                    currentOrder={jobTimeTable.currentOrder}
                    error={jobTimeTable.error}
                    loading={jobTimeTable.loading}
                    tableOffset={jobTimeTable.tableOffset}
                    onSetOrderBy={jobTimeTable.onSetOrderBy}
                    component={
                        <>
                            {/* <SearchForm
                                resetSearch={jobTimeTable.resetSearch}
                                onSearch={jobTimeTable.onSearch}
                                searchPlaceHolder="Search by name or email"
                            /> */}

                            <Formik
                                initialValues={initialValue}
                                enableReinitialize
                                onSubmit={onTimesheetSubmit}
                                validationSchema={timeSheetFilterValidation}
                            >
                                {(props) => <TimeSheetForm {...props} />}
                            </Formik>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default RelieverJobHours;
