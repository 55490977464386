import moment from "moment";
import { array, boolean, date, mixed, number, object, ref, string } from "yup";
import { CombineDateAndTime, JobFilterFields, RepeatInterface, ReportFormFields, status, TimesheetFormFields } from "./interface";
import { serializeQueryParams } from "./helper";
import { default as momentTimeZone } from "moment-timezone";

const stringValidation = string().trim();

const stringValidationWithLimit = stringValidation
    .max(50, "Maximum 50 character allowed")
    .matches(/[a-zA-Z]/, "Must contain at least one character");

const emailValidation = stringValidation.email("Invalid email").matches(/@[^.]*\./, "Invalid email");

const numberErrorMessage = "Please select valid number";

const dateErrorMessage = "Please select valid date";

const getMaxDateForDob = () => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    return maxDate;
};

const mobileNumberValidation = stringValidation.matches(
    /^(\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    "Mobile number is not valid"
);

const numberValidation = number().typeError("Please select valid number");
const fileValidation = mixed().required("File is required");

export const loginValidation = object({
    email: emailValidation.required("Please enter email"),
    password: string().min(8, "Password length must be minimum 8 character").required("Please enter password"),
});

export const centerSelectionValidation = object({
    centreName: stringValidation.required("Centre name is required"),
    centreId: numberValidation,
});

export const addOrEditSchedulerValidation = object({
    email: emailValidation.required("Please enter email"),
    name: stringValidation.required("Please enter name"),
    mobileNumber: mobileNumberValidation.required("Please enter mobile number"),
    dob: date().nullable().max(getMaxDateForDob(), "Minimum age must be 18 years. Please select valid date").typeError(dateErrorMessage),
    centre: array().of(centerSelectionValidation).min(1, "Please select at least one centre").required("Please select center"),
});

export const addEditRelieverValidation = object({
    email: emailValidation.required("Please enter email"),
    wage: number().min(0, "Please select valid value").required("Please enter wage").typeError(numberErrorMessage),
    position: object().required("Please select position"),
    name: stringValidation.required("Please enter name"),
    grade: stringValidation.required("Please enter valid grade"),
    registrationNo: number()
        .nullable()
        .notRequired()
        .typeError(numberErrorMessage)
        .when("position", (position, schema) => {
            if (position[0]?.positionId === 1) {
                return schema
                    .integer("Please enter valid registration number")
                    .positive("Registration number should be positive only")
                    .required("Please enter reliever registration number")
                    .typeError("Please enter registration number");
            }
            return schema;
        }),
    expiryOfRegistration: date()
        .nullable()
        .notRequired()
        .when("position", (position, schema) => {
            if (position[0]?.positionId === 1) {
                return date()
                    .min(new Date(), "Please select valid expiry date")
                    .required("Please select expiry date")
                    .typeError("Please select expiry date");
            }
            return schema;
        }),
    mobileNumber: mobileNumberValidation.required("Please enter mobile number"),
    dob: date().required().max(getMaxDateForDob(), "Minimum age must be 18 years. Please select valid date").typeError(dateErrorMessage),
    region: object().required("Please select region"),
    suburb: object().required("Please select suburb"),
    district: object().required("Please select the district"),
    aidCertificate: boolean(),
    expiryOfAidCertificate: date()
        .nullable()
        .when("aidCertificate", (aidCertificate, schema) => {
            if (aidCertificate[0]) {
                return date().required("Please select expiry date").typeError("Please select valid expiry date");
            }
            return schema;
        })
        .typeError(dateErrorMessage),
    streetAddress: stringValidation.required("Please enter street address"),
    addressLine: stringValidation,
    postalCode: stringValidation.min(4).max(4).required("Please enter postal code"),
    account: stringValidation.required("Please enter bank account"),
    bank: stringValidation.required("Please enter bank name"),
    primaryProofType: stringValidation.required("Please select primary proof type"),
    secondaryProofType: stringValidation.required("Please select secondary proof type"),
    IRDNumber: number().min(10000000).max(9999999999).required("Please enter IRD number"),
    expiryOfPrimaryProofType: date()
        .nullable()
        .when("primaryProofType", (primaryProofType, schema) => {
            if (primaryProofTypeReqDate.includes(String(primaryProofType))) {
                return date().required("Please select primary expiry date").typeError("Please select valid expiry date");
            }
            return schema;
        }),
    expiryOfSecondaryProofType: date()
        .nullable()
        .when("secondaryProofType", (secondaryProofType, schema) => {
            if (secondaryProofTypeReqDate.includes(String(secondaryProofType))) {
                return date().required("Please select secondary expiry date").typeError("Please select valid expiry date");
            }
            return schema;
        }),
});

export const documentValidation = object({
    expireDate: date().nullable(),
    documentType: stringValidation.required(),
    status: stringValidation.required(),
});

export const regionValidation = object({
    regionName: stringValidation.required(),
    regionId: number().required().typeError(numberErrorMessage),
});

export const districtValidation = object({
    districtName: stringValidation.required(),
    districtId: number().required().typeError(numberErrorMessage),
});

export const schedulerSelectionValidation = object({
    name: stringValidation.required(),
    schedulerId: number().required().typeError(numberErrorMessage),
});

export const ageGroupSelectionValidation = object({
    name: stringValidation.required(),
    ageGroupId: number().required().typeError(numberErrorMessage),
});

export const changePasswordValidation = object({
    oldPassword: string().required("Please enter your current password"),
    newPassword: string()
        .required("Please enter new password")
        .notOneOf([ref("oldPassword")], "Passwords must be different from current password")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"),
    confirmPassword: string()
        .oneOf([ref("newPassword")], "Passwords must match")
        .required("Please enter new password again"),
});

export const addOrEditCentreValidation = object({
    suburbId: number().required("Please select suburb").typeError(numberErrorMessage),
    region: object().required("Please select region"),
    suburb: object().required("Please select suburb"),
    district: object().required("Please select the district"),
    centreName: stringValidation.required("Please enter name of centre"),
    postalCode: number().required("Please enter postal code").typeError("Invalid postal code"),
    schedulerId: array().of(schedulerSelectionValidation).nullable(),
    streetAddress: stringValidation.required("Please enter street address"),
});

export const positionSelectionValidation = object({
    name: stringValidation.required(),
    positionId: number().required().typeError(numberErrorMessage),
});

export const breakSelectionValidation = object({
    breakTime: stringValidation.required(),
    breakId: number().required().typeError(numberErrorMessage),
});

export const relieverForJobValidation = object({
    name: stringValidation.required(),
    relieverId: number().required().typeError(numberErrorMessage),
});

export const repeatValue = ["Don't repeat", "Same week", "Every week"];

export const RelieverStatus = [status.available, status.unavailable, status.accepted];

export const daysOfWeeks = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const MIN_YEAR = 1950,
    TEN = 10;

export const getTodayDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
};

export const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const addOrEditJobValidation = object().shape({
    centreForJob: object().required("Please select centre"),
    scheduler: schedulerSelectionValidation.required("Please select scheduler"),
    ageGroupForJob: ageGroupSelectionValidation.required("Please select age group"),
    breakForJob: breakSelectionValidation.required("Please select break"),
    repeat: string().required("Please select repeat value"),
    positionForJob: positionSelectionValidation.required("Please select position"),
    startTime: string().required("Please select start time"),
    daysOfWeek: array().when("repeat", (value: string[]) => {
        if (value[0] === repeatValue[1] || value[0] === repeatValue[2]) {
            const validationForDay = array().min(1, "Please select at least one day").required("Please select day");

            if (value[0] === repeatValue[2]) {
                return validationForDay;
            }

            return validationForDay.test("is-valid-day", "Please select valid day", function (value) {
                const date = new Date(this.parent.date);

                if (!value) {
                    return false;
                }

                for (const dayOfWeek of value) {
                    const dayOfWeekIndex = daysOfWeeks.findIndex((day) => day === dayOfWeek.value);
                    if (date.getDay() > dayOfWeekIndex) {
                        return false;
                    }
                }
                return true;
            });
        } else {
            return array();
        }
    }),
    endTime: string()
        .required("Please select end time")
        .test("is-greater", "End time must be greater than start time", function (value) {
            const startTime = this.parent.startTime;
            const endTime = value;

            if (!startTime || !endTime) {
                return false;
            }

            const start = new Date(`2000-01-01 ${startTime}`);
            const end = new Date(`2000-01-01 ${endTime}`);

            return end > start;
        }),
    noOfRelievers: number().required("Please select the number of reliever").typeError(numberErrorMessage),
    startDate: date().min(getTodayDate(), "Please select valid a date").required("Please select a date").typeError(dateErrorMessage),
    endDate: date().when(["repeat", "startDate"], (value: string[]) => {
        if (value[0] === repeatValue[2]) {
            if (value[1]) {
                const minDate = new Date(value[1]);
                minDate.setDate(minDate.getDate() + 7);
                return date().min(minDate, "Please select valid end date").required("Please select end date").typeError(dateErrorMessage);
            } else {
                return date().required("Please select end date").typeError(dateErrorMessage);
            }
        } else {
            return date().nullable();
        }
    }),
    relieverSelection: boolean().when(["inFavorite", "inDistrict", "sendSpecific"], (value: boolean[]) => {
        if (!value.includes(true)) {
            return boolean().isFalse("One field required between these three");
        }
        return boolean();
    }),
    inFavorite: boolean(),
    inDistrict: boolean(),
    sendSpecific: boolean(),
    reliever: array()
        .of(relieverForJobValidation)
        .nullable()
        .when("sendSpecific", (sendSpecific, schema) => {
            if (sendSpecific[0]) {
                return schema.nonNullable().required("Please select reliever");
            }
            return schema;
        }),
});

export const addOrEditPositionValidation = object({
    name: stringValidationWithLimit.required("Please enter position"),
});

export const addOrEditAgeGroupValidation = object({
    name: stringValidationWithLimit.required("Please enter age group"),
});

export const addOrEditInvoiceValidation = object({
    fromDate: date().required("Please enter from date"),
    toDate: date()
        .required("Please select to date")
        .test("is-greater", "To date must be greater than from date", function (value) {
            const startTime = this.parent.fromDate;
            const endTime = value;

            if (!startTime || !endTime) {
                return false;
            }

            const start = new Date(startTime);
            const end = new Date(endTime);

            return end > start;
        }),
    description: stringValidation.required("Please enter description"),
    amount: number().min(0, "Please select valid value").required("Please enter amount").typeError(numberErrorMessage),
    schedulerId: number().required().typeError(numberErrorMessage),
    invoiceFile: fileValidation,
    dueDate: date().required("Please enter due date"),
});

export const editJobTimeValidation = object({
    clockIn: string().required("Please select clock in time"),
    clockOut: string()
        .required("Please select clock out time")
        .test("is-greater", "Clock out time must be greater than clock in time", function (value) {
            const startTime = this.parent.clockIn;
            const endTime = value;

            if (!startTime || !endTime) {
                return false;
            }

            const start = new Date(`2000-01-01 ${startTime}`);
            const end = new Date(`2000-01-01 ${endTime}`);

            return end > start;
        }),
});

export const addOrEditBreakValidation = object({
    breakTime: stringValidationWithLimit.required("Please enter break time"),
    time: number().min(0, "Please select valid value").typeError(numberErrorMessage),
});

export const searchFormValidation = object({
    search: string().required("Please enter value"),
});

export const PageEnum = <const>["dashboard", "employee", "scheduler"];

export const documentStatus = <const>["Pending", "Rejected", "Public", "Private"];

export enum DocumentType {
    ACADEMIC_TRANSCRIPTS = "Academic Transcripts",
    PORTRAIT = "Portrait",
    CV = "CV",
    PRIMARY_PROOF_FILE = "Primary ProofFile",
    SECONDARY_PROOF_FILE = "Secondary ProofFile",
    WORKING_HOURS_LETTER = "Working Hours Letter",
    IRD_FILE = "IRD File",
    GENERAL = "General",
}

export enum ApprovalTypes {
    PENDING = "Pending",
    APPROVE = "Approved",
    REJECTED = "Rejected",
}

export const ModeEnum = <const>["light", "dark"];

export const ReportUserType: ReportFormFields[] = [ReportFormFields.scheduler, ReportFormFields.reliever];

export const TimeSheetUserType: TimesheetFormFields[] = [
    TimesheetFormFields.scheduler,
    TimesheetFormFields.reliever,
    TimesheetFormFields.centre,
];

export const JobFilterFieldsType: JobFilterFields[] = [
    JobFilterFields.date,
    JobFilterFields.scheduler,
    JobFilterFields.centre,
    JobFilterFields.position,
    JobFilterFields.reliever,
];

export const maxTimeForGlobalSetting = 12;

export const globalSettingNumberValidation = number()
    .min(0, "Please select valid value")
    .max(maxTimeForGlobalSetting, "Please select valid value")
    .typeError(numberErrorMessage);

export const updateGlobalSettingValidation = object({
    cancelJob: globalSettingNumberValidation.required("Please enter deadline time for cancel job"),
    updateJob: globalSettingNumberValidation.required("Please enter deadline time for update job"),
    withdrawJob: globalSettingNumberValidation.required("Please enter deadline time for withdraw job"),
    createJob: globalSettingNumberValidation.required("Please enter deadline time for create job"),
    deactivationEmail: emailValidation.required("Please enter email"),
    contactUsEmail: emailValidation.required("Please enter email"),
});

export const sendNotificationValidation = object({
    deviceType: stringValidation.label("device type"),
    userType: stringValidation.label("user type"),
    title: stringValidation.required(),
    description: stringValidation.required(),
});

export const reportValidation = object({
    fieldName: string().required("Please select a field value"),
    from: date()
        .max(moment().subtract(1, "day"), "Date must be before today")
        .required("Please select the date")
        .typeError(dateErrorMessage),
    to: date().max(moment().subtract(1, "day"), "Date must be before today").required("Please select the date").typeError(dateErrorMessage),

    scheduler: object().when("fieldName", (fieldName) => {
        if (fieldName[0] === "Scheduler") {
            return object().required("Please select a scheduler");
        } else {
            return object().nullable();
        }
    }),

    reliever: object().when("fieldName", (fieldName) => {
        if (fieldName[0] === "Reliever") {
            return object().required("Please select a reliever");
        } else {
            return object().nullable();
        }
    }),
});

export const combineDateAndTime = (
    startDate: Date | null | string,
    startTime: string,
    endDate: Date | null | string,
    endTime: string,
    repeat: RepeatInterface | null,
    dayOfWeek?: string[]
): Promise<CombineDateAndTime> => {
    return new Promise((resolve) => {
        const startDateRef = new Date(startDate ?? ""),
            formattedStartDate = moment(startDateRef).format("YYYY-MM-DD");

        let endDateForReturn = new Date(moment(`${formattedStartDate} ${endTime}`, dateFormat).format());

        if (repeat === repeatValue[1] && dayOfWeek) {
            startDateRef?.setDate(
                startDateRef.getDate() - startDateRef.getDay() + (daysOfWeeks.indexOf(dayOfWeek[dayOfWeek.length - 1]) + 1)
            );

            endDateForReturn = new Date(moment(`${moment(startDateRef).format("YYYY-MM-DD")} ${endTime}`, dateFormat).format());
        }

        if (repeat === repeatValue[2]) {
            endDateForReturn = new Date(moment(`${endDate} ${endTime}`, dateFormat).format());
        }

        const startDateForResponse = new Date(moment(`${formattedStartDate} ${startTime}`, dateFormat).format());

        resolve({ startDate: startDateForResponse, endDate: endDateForReturn });
    });
};

export const getImageUrl = (img: string | File): string => {
    if (typeof img === "string") {
        return img;
    } else if (img) {
        return URL.createObjectURL(img);
    }
    return "";
};

export const showOnlyActiveUser = () => {
    return serializeQueryParams({ showOnlyActive: true });
};

export const availabilityValidation = object({
    date: date().required("Please select the date").typeError(dateErrorMessage),
    position: object().required("Please select a position"),
    status: string().required("Please select a status"),
});

export const specificUserValidation = object({
    title: stringValidation.required(),
    description: stringValidation.required(),
});

export enum User_Type {
    ALL = "All",
    SCHEDULER = "Scheduler",
    RELIEVER = "Reliever",
}

export enum Filter_Type {
    WEEK = "Week",
    MONTH = "Month",
    YEAR = "Year",
    CUSTOM = "Custom",
}

export const dashboardFilterValidation = object({
    from: date().nullable(),
    to: date()
        .nullable()
        .test("is-greater", "To date must be greater than  from date", function (value) {
            const startTime = this.parent.from;
            const endTime = value;

            if (!startTime) return true;

            if (startTime && !endTime) return this.createError({ message: "To date is required" });

            const start = new Date(startTime);
            const end = new Date(`${endTime}`);

            return end > start;
        }),
});

export const timeSheetFilterValidation = object({
    startDate: date().nullable(),
    endDate: date()
        .nullable()
        .test("is-greater", "To date must be greater than  from date", function (value) {
            const startTime = this.parent.startDate;
            const endTime = value;

            if (!startTime) return true;

            if (startTime && !endTime) return this.createError({ message: "To date is required" });

            const start = new Date(startTime);
            const end = new Date(`${endTime}`);

            return end > start;
        }),
});

export const primaryProofTypeReqDate = ["Passport", "NZ Firearms License", "NZ Emergency Travel Document", "NZ Certificate of Identity"];

export const secondaryProofTypeReqDate = [
    "Original Driver’s License",
    "18+ card",
    "Community Services card",
    "NZ Student Photo Identification Card",
    "NZ Teachers Registration certificate",
    "International Driving Permit",
];

export const userTypeValue = Object.values(User_Type).map((value) => ({ value, label: value }));

export const NZTimeZone = "Pacific/Auckland";

export const camelCaseToTitleCase = (input: string): string => {
    const result = input.replace(/([A-Z])/g, " $1").trim();
    return result.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const isDateInCurrentWeek = (date: Date | string) => {
    const NZDate = moment(date).tz(NZTimeZone);
    const NZToday = momentTimeZone.utc().tz(NZTimeZone);
    const startOfWeek = NZToday.clone().startOf("isoWeek");

    const isDateExist = moment(NZDate).isBetween(startOfWeek, NZToday);

    return isDateExist;
};
