import { Formik } from "formik";
import { addOrEditCentreValidation } from "../../helper/constant";
import AddOrEditCentre from "./AddOrEditCentre";
import { CentreInterface } from "../../helper/interface";
import { onAddCentre } from "../../service/centre";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePathName from "../../hooks/usePathName";
import { ArrowLeftCircle } from "react-feather";

const AddCentre = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    usePathName([
        { pathName: "Centre", href: "/centre" },
        { pathName: "Add", href: "/centre/add " },
    ]);

    const onSubmit = (values: CentreInterface) => {
        onAddCentre(values, dispatch, navigate);
    };

    const initialValues: CentreInterface = {
        streetAddress: "",
        centreName: "",
        addressLine: "",
        postalCode: null,
        region: null,
        district: null,
        suburb: null,
        schedulerId: null,
        showSelectReliever: true,
    };

    return (
        <div className="intro-y box py-10 sm:py-10 mt-5">
            <div className="flex w-full items-center">
                <div className="flex-2 ml-4">
                    <ArrowLeftCircle className="cursor-pointer text-primary" onClick={() => navigate("/centre")} />
                </div>
                <div className="flex-1 font-medium text-center text-lg -ml-2">Add new centre</div>
            </div>
            <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-slate-200/60 dark:border-darkmode-400">
                <Formik initialValues={initialValues} validationSchema={addOrEditCentreValidation} onSubmit={onSubmit}>
                    {(props) => <AddOrEditCentre {...props} />}
                </Formik>
            </div>
        </div>
    );
};

export default AddCentre;
