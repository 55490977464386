import { Form } from "formik";
import {
    CentreForSelect,
    FormInterface,
    RelieverForSelect,
    SchedulerForSelect,
    StateInterface,
    TimeSheetFilterValues,
    TimesheetFormFields,
} from "../../helper/interface";
import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { Listbox, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { TimeSheetUserType } from "../../helper/constant";
import Select, { SingleValue } from "react-select";
import CustomDatePicker from "../datepicker/CustomDatePicker";

const TimeSheetForm = ({
    values,
    setFieldTouched,
    setFieldValue,
    resetForm,
    errors,
    touched,
    dirty,
}: FormInterface<TimeSheetFilterValues>) => {
    const schedulersData = useFetch<SchedulerForSelect[]>(`${api.admin.schedulersForCentre}`);

    const loading = useSelector((state: StateInterface) => state.base.loading);

    const relieverData = useFetch<RelieverForSelect[]>(`${api.admin.reliever}`);

    const centreData = useFetch<CentreForSelect[]>(`${api.admin.getCentre}`);

    const handleOptionChange = (value: TimesheetFormFields) => {
        resetForm();

        setFieldValue("fieldName", value);
    };

    const handleResetField = () => {
        resetForm();
    };

    return (
        <Form id="tabulator-html-filter-form" className="flex items-center align-items-baseline report-form">
            <div className="flex items-center sm:mr-4 py-1" style={{ zIndex: 60 }}>
                <label className="flex-none mr-2 mb-0">Select user type</label>
                <div className="tom-select">
                    <Listbox value={values.fieldName} onChange={handleOptionChange}>
                        <Listbox.Button className="form-control ts-input">
                            <div className="item">{values?.fieldName ?? "Select a scheduler"}</div>
                        </Listbox.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                            onBlur={() => setFieldTouched("fieldName", true)}
                        >
                            <Listbox.Options className="ts-dropdown single w-full">
                                <div role="listbox" className="ts-dropdown-content">
                                    {TimeSheetUserType ? (
                                        <>
                                            {TimeSheetUserType.map((data) => (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || values?.fieldName === data ? "optionColor" : null
                                                        }`
                                                    }
                                                    key={data}
                                                    value={data}
                                                >
                                                    {data}
                                                </Listbox.Option>
                                            ))}
                                        </>
                                    ) : null}
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </Listbox>
                </div>
            </div>

            {TimesheetFormFields.scheduler === values.fieldName ? (
                <div className="flex items-center formField">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label className="mb-0 mr-2">Scheduler</label>
                            {schedulersData?.res?.data ? (
                                <Select
                                    name="schedulerId"
                                    options={schedulersData?.res?.data}
                                    value={schedulersData?.res?.data.find((item) => item.schedulerId === values.schedulerId)}
                                    onBlur={() => setFieldTouched("schedulerId", true)}
                                    onChange={(selectedItem: SingleValue<SchedulerForSelect>) => {
                                        setFieldValue("schedulerId", selectedItem?.schedulerId);
                                    }}
                                    className={`form-control tom-select ${errors.schedulerId && touched.schedulerId && "border-danger"}`}
                                    classNamePrefix="select"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.schedulerId}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            {TimesheetFormFields.reliever === values.fieldName ? (
                <div className="flex items-center formField">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label className="mb-0 mr-2">Reliever</label>
                            {relieverData?.res?.data ? (
                                <Select
                                    name="relieverId"
                                    options={relieverData?.res?.data}
                                    value={relieverData?.res?.data.find((item) => item.relieverId === values.relieverId)}
                                    onBlur={() => setFieldTouched("relieverId", true)}
                                    onChange={(selectedItem: SingleValue<RelieverForSelect>) => {
                                        setFieldValue("relieverId", selectedItem?.relieverId);
                                    }}
                                    className={`form-control tom-select ${errors.relieverId && touched.relieverId && "border-danger"}`}
                                    classNamePrefix="select"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.relieverId}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            {TimesheetFormFields.centre === values.fieldName ? (
                <div className="flex items-center formField">
                    <div className="flex flex-col items-end">
                        <div className="flex items-center">
                            <label className="mb-0 mr-2">Centre Name</label>
                            {centreData?.res?.data ? (
                                <Select
                                    name="centreId"
                                    options={centreData?.res?.data}
                                    value={centreData?.res?.data.find((item) => item.centreId === values.centreId)}
                                    onBlur={() => setFieldTouched("centreId", true)}
                                    onChange={(selectedItem: SingleValue<CentreForSelect>) => {
                                        setFieldValue("centreId", selectedItem?.centreId);
                                    }}
                                    className={`form-control tom-select ${errors.centreId && touched.centreId && "border-danger"}`}
                                    classNamePrefix="select"
                                    getOptionLabel={(option) => option.centreName}
                                    getOptionValue={(option) => option.centreId}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            <div>
                <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                    <label htmlFor="startDate" className="mr-2 w-16 sm:w-auto">
                        From
                    </label>
                    <CustomDatePicker
                        id="startDate"
                        name="startDate"
                        maxDate={new Date()}
                        selected={values.startDate ? new Date(values.startDate) : null}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>

            <div>
                <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                    <label htmlFor="endDate" className="mr-2 w-16 sm:w-auto">
                        To
                    </label>
                    <CustomDatePicker
                        id="endDate"
                        name="endDate"
                        maxDate={new Date()}
                        selected={values.endDate ? new Date(values.endDate) : null}
                        setFieldValue={setFieldValue}
                        hasError={Boolean(errors.endDate && touched.endDate)}
                    />
                </div>
                {errors.endDate && <div className="text-danger">{errors.endDate}</div>}
            </div>

            <div className="mt-2 ml-4 report-action-button">
                <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16" disabled={loading || !dirty}>
                    Go
                </button>
                <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    className="btn btn-secondary w-16 ml-2"
                    onClick={handleResetField}
                    disabled={!dirty}
                >
                    Reset
                </button>
            </div>
        </Form>
    );
};

export default TimeSheetForm;
