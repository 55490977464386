import { Dispatch } from "@reduxjs/toolkit";
import { ActivateDeactivateCentreInterface, CentreInterface, ResponseInterface } from "../helper/interface";
import { NavigateFunction } from "react-router-dom";
import { setLoading } from "../store/slice/BaseSlice";
import { dataService } from "../config/DataService";
import { api } from "../config/Api";
import { addSuccessToast, editSuccessToast, successToast } from "../helper/toast";
import { handleCatchResponse } from "../helper/helper";
import { AxiosError } from "axios";

export const onAddCentre = async (data: CentreInterface, dispatch: Dispatch, navigate: NavigateFunction) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            centreName: data.centreName,
            postalCode: data?.postalCode,
            schedulerId: data.schedulerId?.map((ele) => ele.schedulerId),
            streetAddress: data.streetAddress,
            ...(data.addressLine ? { addressLine: data.addressLine } : {}),
            suburbId: data.suburbId,
            showSelectReliever: data.showSelectReliever,
        };

        const response = await dataService.post(api.admin.addCentre, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        const res: Required<ResponseInterface<CentreInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Add new",
            primaryLink: "/centre/add",
            secondaryLinkTitle: "Edit",
            secondaryLink: `/centre/edit/${res.data.centreId}`,
        };

        addSuccessToast(res.message, navigate, toastLink);

        navigate("/centre");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};

export const onActivateDeactivateCentre = async (data: ActivateDeactivateCentreInterface, dispatch: Dispatch, reload: () => void) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            isActive: data.isActive,
        };

        const response = await dataService.patch(`${api.admin.activateDeactivateCentre}/${data.centreId}`, payload);

        const res = response.data;

        successToast(res.message);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onDeleteCentre = async (
    centreId: number,
    dispatch: Dispatch,
    reload: () => void,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        dispatch(setLoading(true));

        const response = await dataService.delete(`${api.admin.deleteCentre}/${centreId}`);

        const res = response.data;

        successToast(res.message);

        setIsOpen(false);
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
        reload();
    }
};

export const onEditCentre = async (data: CentreInterface, navigate: NavigateFunction, dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));

        const payload = {
            centreName: data.centreName,
            postalCode: data.postalCode,
            schedulerId: data.schedulerId?.map((ele) => ele.schedulerId),
            streetAddress: data.streetAddress,
            ...(data.addressLine ? { addressLine: data.addressLine } : {}),
            suburbId: data.suburbId,
            showSelectReliever: data.showSelectReliever,
        };

        const response = await dataService.put(`${api.admin.editCentre}/${data.centreId}`, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        const res: Required<ResponseInterface<CentreInterface>> = response.data;

        const toastLink = {
            primaryLinkTitle: "Edit",
            primaryLink: `/centre/edit/${res.data.centreId ?? data.centreId}`,
        };

        editSuccessToast(res.message, navigate, toastLink);
        navigate("/centre");
    } catch (error) {
        handleCatchResponse(error as AxiosError);
    } finally {
        dispatch(setLoading(false));
    }
};
